export class Permissions {
  public static readonly TrialRead: string = "TrialRead";
  public static readonly TrialPublicInfoRead: string = "TrialPublicInfoRead";
  public static readonly TrialAdd: string = "TrialAdd";
  public static readonly TrialEditSensitive: string = "TrialEditSensitive";
  public static readonly TrialEditPublic: string = "TrialEditPublic";
  public static readonly TrialSitesRead: string = "TrialSitesRead";
  public static readonly TrialSiteAssign: string = "TrialSiteAssign";
  public static readonly TrialSiteUnassign: string = "TrialSiteUnassign";
  public static readonly TrialSiteEdit: string = "TrialSiteEdit";
  public static readonly TrialPatientsRead: string = "TrialPatientsRead";
  public static readonly TrialPatientAssign: string = "TrialPatientAssign";
  public static readonly TrialPolicyRead: string = "TrialPolicyRead";
  public static readonly TrialPolicyAdd: string = "TrialPolicyAdd";
  public static readonly TrialPolicyEdit: string = "TrialPolicyEdit";
  public static readonly TrialPolicyDelete: string = "TrialPolicyDelete";
  public static readonly TrialSupportContactsRead: string = "TrialSupportContactsRead";
  public static readonly TrialSupportContactsAdd: string = "TrialSupportContactsAdd";
  public static readonly TrialSupportContactsDelete: string = "TrialSupportContactsDelete";
  public static readonly TrialChangeLogRead: string = "TrialChangeLogRead";
  public static readonly TrialChangeLogExport: string = "TrialChangeLogExport";
  public static readonly TrialPaymentsRead: string = "TrialPaymentsRead";
  public static readonly TrialPaymentsCaxton: string = "TrialPaymentsCaxton";
  public static readonly TrialExportTripData: string = "TrialExportTripData";
  public static readonly TrialAutoStipend: string = "TrialAutoStipend";
  public static readonly TrialBooking: string = "TrialBooking";
  public static readonly TrialBookingFull: string = "TrialBookingFull";
  public static readonly TrialCmsUsers: string = "TrialCmsUsers";

  public static readonly SiteRead: string = "SiteRead";
  public static readonly SitePublicRead: string = "SitePublicRead";
  public static readonly SiteAdd: string = "SiteAdd";
  public static readonly SiteEdit: string = "SiteEdit";
  public static readonly SiteDelete: string = "SiteDelete";
  public static readonly SiteTrialsRead: string = "SiteTrialsRead";
  public static readonly SiteTrialEdit: string = "SiteTrialEdit";
  public static readonly SiteUnassignTrial: string = "SiteUnassignTrial";
  public static readonly SiteExportCsv: string = "SiteExportCsv";

  public static readonly PatientRead: string = "PatientRead";
  public static readonly PatientWrite: string = "PatientWrite";
  public static readonly PatientDelete: string = "PatientDelete";
  public static readonly PatientApprove: string = "PatientApprove";
  public static readonly PatientExportCsv: string = "PatientExportCsv";
  public static readonly PatientVisitsRead: string = "PatientVisitsRead";
  public static readonly PatientVisitsWrite: string = "PatientVisitsWrite";
  public static readonly PatientExpenseWrite: string = "PatientExpenseWrite";
  public static readonly PatientExpenseApproveReject: string = "PatientExpenseApproveReject";
  public static readonly PatientExpenseEditInvestigation: string = "PatientExpenseEditInvestigation";
  public static readonly PatientDetailsRead: string = "PatientDetailsRead";
  public static readonly PatientDetailsWrite: string = "PatientDetailsWrite";
  public static readonly PatientNotesRead: string = "PatientNotesRead";
  public static readonly PatientNotesWrite: string = "PatientNotesWrite";
  public static readonly PatientActivityRead: string = "PatientActivityRead";
  public static readonly PatientChangeLogRead: string = "PatientChangeLogRead";
  public static readonly PatientChangeLogExport: string = "PatientChangeLogExport";
  public static readonly PatientPaymentInfoRead: string = "PatientPaymentInfoRead";
  public static readonly PatientPaymentInfoReadBankDetails: string = "PatientPaymentInfoReadBankDetails";
  public static readonly PatientPaymentInfoWrite: string = "PatientPaymentInfoWrite";

  public static readonly VisitRead: string = "VisitRead";
  public static readonly VisitAdd: string = "VisitAdd";
  public static readonly VisitEdit: string = "VisitEdit";
  public static readonly VisitDelete: string = "VisitDelete";
  public static readonly VisitTripRead: string = "VisitTripRead";
  public static readonly VisitTripAdd: string = "VisitTripAdd";
  public static readonly VisitTripEdit: string = "VisitTripEdit";
  public static readonly VisitTripDelete: string = "VisitTripDelete";

  public static readonly ExpenseRead: string = "ExpenseRead";
  public static readonly ExpenseWrite: string = "ExpenseWrite";
  public static readonly ExpenseApproveReject: string = "ExpenseApproveReject";
  public static readonly ExpenseExportCsv: string = "ExpenseExportCsv";
  public static readonly ExpenseCategoryRead: string = "ExpenseCategoryRead";
  public static readonly ExpenseCategoryAdd: string = "ExpenseCategoryAdd";
  public static readonly ExpenseCategoryEdit: string = "ExpenseCategoryEdit";
  public static readonly ExpenseCategoryDelete: string = "ExpenseCategoryDelete";

  public static readonly TravelRequestProcess: string = "TravelRequestProcess";
  public static readonly TravelRequestDelete: string = "TravelRequestDelete";

  public static readonly ReportsDashboardRead: string = "ReportsDashboardRead";

  public static readonly UserRead: string = "UserRead";
  public static readonly UserAdd: string = "UserAdd";
  public static readonly UserEdit: string = "UserEdit";
  public static readonly UserDeactivate: string = "UserDeactivate";
  public static readonly UserChangeLogRead: string = "UserChangeLogRead";
  public static readonly SiteContactRead: string = "SiteContactRead";
  public static readonly SiteContactWrite: string = "SiteContactWrite";
  public static readonly SendSiteContactRegistrationLink: string = "SendSiteContactRegistrationLink";
  public static readonly SiteContactChangeLogRead: string = "SiteContactChangeLogRead";
  public static readonly UserChangeLogExport: string = "UserChangeLogExport";

  public static readonly OverBudgetRequestApprove: string = "OverBudgetRequestApprove";
  public static readonly OverBudgetRequestReject: string = "OverBudgetRequestReject";
  public static readonly OverBudgetRequestReferBack: string = "OverBudgetRequestReferBack";
  public static readonly OverBudgetRequestDelete: string = "OverBudgetRequestDelete";

  public static readonly CaxtonTopLevel: string = "CaxtonTopLevel";
}
