import { Component, ElementRef, EventEmitter, Input, Output, Renderer2, ViewChild } from '@angular/core';
import { UntypedFormGroup } from "@angular/forms";

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss']
})
export class SearchInputComponent {
  @Output() inputChange = new EventEmitter<string>();
  @Input() parentForm: UntypedFormGroup;
  @Input() name: string;
  @Input('debounceMs') debounceMs = 1000;

  @ViewChild('searchContainer') searchContainer: ElementRef;
  @ViewChild('inputContainer') container: ElementRef;
  @ViewChild('inputField') input: ElementRef;

  inputHasValue = false;
  mobileIsVisible = false;
  delayTimerReference = null;

  constructor(private renderer: Renderer2) { }

  onInputValueChanged(event: any) {
    clearTimeout(this.delayTimerReference);

    this.delayTimerReference = setTimeout(() => {
      const value = this.input.nativeElement.value;
      this.inputHasValue = !!value;
      this.inputChange.emit(value);
    }, this.debounceMs);
  }

  onClearInput() {
    this.inputHasValue = false;
    this.input.nativeElement.value = '';
    setTimeout(() => {
      this.inputChange.emit('');
    }, 250);
  }

  onMobileIconClick() {
    if (!this.mobileIsVisible) {
      this.renderer.addClass(this.searchContainer.nativeElement, 'mobile-visible');
    } else {
      this.renderer.removeClass(this.searchContainer.nativeElement, 'mobile-visible');
    }
  }

}
