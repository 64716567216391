import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { environment } from 'environments/environment';
import { GlobalConstants } from '../constants/global-constants';
import { AuthStatus } from "../auth-status.enum";
import { InteractionStatus } from "@azure/msal-browser";
import { LogHelper } from "../helpers/log.helper";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  rolesLoaded = new BehaviorSubject<boolean>(false);
  authStatus = new BehaviorSubject<AuthStatus>(AuthStatus.Unauthenticated);

  constructor(private readonly http: HttpClient, private readonly router: Router, private readonly authService: MsalService, private readonly msalBroadcastService: MsalBroadcastService) {
    this.msalBroadcastService.inProgress$.subscribe((status) => {

      if (status === InteractionStatus.Startup && this.loggedIn()) {
        this.authStatus.next(AuthStatus.Authenticated);

        // In case of a page refresh, check if roles have been loaded
        if (this.rolesLoaded)
          this.rolesLoaded.next(true);
      }
    });
  }

  /**
 * This method logs a user out by clearing the local AuthToken and redirecting the user to the login page
 * if shouldRedirect = true
 * @param shouldRedirect
 */
  logout(shouldRedirect: boolean = true) {
    this.authStatus.next(AuthStatus.Unauthenticated);
    this.rolesLoaded.next(false);

    this.authService.logoutRedirect({
      onRedirectNavigate: () => {
        return false;
      }
    });

    let expenseOrderBy =  localStorage.getItem("order_by");
    localStorage.clear();
    localStorage.setItem("order_by", expenseOrderBy)

    if (shouldRedirect) {
      void this.router.navigate(['/']);
    }
  }

  /**
   * This method can be called to check if a users roles have been stored in local storage
   */
  rolesAreStored(): boolean {
    const userRolesPayload = localStorage.getItem(GlobalConstants.userRolesStorageKey);
    return userRolesPayload !== null && userRolesPayload !== undefined;
  }

  getRolesFromApi(): Observable<any> {
    return this.http.get<CachedRoles>(environment.apiUrl + '/access/getRoles').pipe(map(item => {
      localStorage.setItem(GlobalConstants.userClaimsStorageKey, JSON.stringify(item.userClaims));
      localStorage.setItem(GlobalConstants.userRolesStorageKey, JSON.stringify(item.userRoles));
      this.rolesLoaded.next(true);
    }));
  }

  hasAnyRole(roles: string): boolean {
    const userRolesPayload = localStorage.getItem(GlobalConstants.userRolesStorageKey);
    const userRolesPayloadArray = JSON.parse(userRolesPayload) as string[] || [];
    roles = roles.toLowerCase();

    return userRolesPayloadArray.some(item => roles.includes(item.toLowerCase()));
  }

  hasPermission(permission: string): boolean{
    const userPermissions = JSON.parse(localStorage.getItem(GlobalConstants.userClaimsStorageKey)) as string[];
    return userPermissions.includes(permission);
  }

  /**
   * Does the current user have a particular role?
   * @param role
   */
  hasRole(role: string): boolean {
    return this.hasAnyRole(role);
  }

  hasNoRoles(): boolean {
    const userClaimsPayload = localStorage.getItem(GlobalConstants.userClaimsStorageKey);
    const userClaimsPayloadArray = JSON.parse(userClaimsPayload) as string[] || [];

    return userClaimsPayloadArray.length === 0;
  }

  loggedIn() {
    return this.authService.instance.getAllAccounts().length > 0 && localStorage.getItem(GlobalConstants.userClaimsStorageKey) !== null;
  }
}

interface CachedRoles { userClaims: string[]; userRoles: string[] }
