import { AutoApprovalResult, ExpenseCategoryType } from './expense.model';
import { ExpenseRuleListItemViewModel, MileageRateListItemViewModel } from "app/features/trial/trial-details/trial-policy.model";

export class ExpenseCheck {
  public policyReminders: string;
  public notes: string;
  public overspendApproved: boolean;
  public bankFeesIncurred: boolean;
  public fixedFeePerVisit: boolean;
  public overspendTravel: boolean;
  public overspendMileage: boolean;
  public approvalNoReceipts: boolean;
  public trialExpensePolicyReminders: string;
  public subTotals: ExpenseCheckSubTotal[];
  public internalPolicyNotes: string;
  public amount: number;
  public currency: string;
  public visitLengthInDays: number;
  public overBudgetRequestRaised: boolean;
  public expenseCategoryType: ExpenseCategoryType;
  public approvalSuggestion?: AutoApprovalResult;
  public caregiverTravelling: string;
  public visitAttendance: string;
  public overBudgetRequestRuleExists: boolean;
  public referredBack: boolean;
  public rules: ExpenseRuleListItemViewModel[];
  public rates: MileageRateListItemViewModel[];
}

export class ExpenseCheckSubTotal {
  public categoryName: string;
  public currency: string;
  public approved: number;
  public pending: number;
  public total: number;
}

export enum CaregiverTravelling {
  No = 0,
  Yes = 1,
  TravelBooked = 3
}

export class CaregiverTravellingString {
  static Values: { [key in CaregiverTravelling]: string } = {
    [CaregiverTravelling.No]: "No or Not Applicable",
    [CaregiverTravelling.Yes]: "Yes",
    [CaregiverTravelling.TravelBooked]: "Travel Booked",
  };
}
