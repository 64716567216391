<app-modal #modal class="check-expense-modal">
  <div class="modal-body">
    <app-expense-card (onEdit)="editExpenseModal.show(expense.id)" [expense]="expense" [showApproveOptions]="false"
      [showDescription]="false" [showExpensePaidBy]="false" [showInvestigation]="false"
      [showReasonForEdit]="false"></app-expense-card>

    <hr>

    <div class="check-tabs tabs-wrap">
      <ul class="nav nav-tabs">
        <li class="nav-item">
          <a class="nav-link" (click)="onTabSelect('approvalSuggestion')"
            [ngClass]="{'active': selectedTab == 'approvalSuggestion'}">Approval Suggestion</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" (click)="onTabSelect('manualChecks')"
            [ngClass]="{'active': selectedTab == 'manualChecks'}">Manual Checks</a>
        </li>
      </ul>
    </div>

    <form class="check-form" [formGroup]="form" *ngIf="expenseCheck">
      <div class="row">
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-12">
              <ng-container *ngIf="selectedTab === 'manualChecks'">
                <div class="form-group">
                  <label>Trial Expense Reminders (shown on all expenses for this trial)</label>
                  <textarea class="form-control" formControlName="trialExpensePolicyReminders"></textarea>
                </div>
                <div class="form-group">
                  <label>Patient level expense notes (shown on all expenses for this patient)</label>
                  <textarea class="form-control" formControlName="policyReminders"></textarea>
                </div>
                <div class="form-group">
                  <label>Expense specific notes</label>
                  <textarea class="form-control" formControlName="notes"></textarea>
                </div>
                <div class="form-group">
                  <label>Internal Policy notes</label>
                  <textarea class="form-control" formControlName="internalPolicyNotes"></textarea>
                </div>
              </ng-container>
              <ng-container *ngIf="selectedTab === 'approvalSuggestion'">
                <app-expense-auto-approval-result #autoApprovalResult [result]="expenseCheck.approvalSuggestion"
                  [expenseClaimId]="expense?.id"
                  (resultUpdated)="updateRulesList($event)"></app-expense-auto-approval-result>
              </ng-container>
            </div>
            <div class="col-md-12">
              <div class="check-options">
                <ng-container *ngIf="selectedTab === 'manualChecks'">
                  <div class="row">
                    <div class="col-6">
                      <div class="checkbox-wrap">
                        <div class="form-group">
                          <label class="checkbox">
                            <input formControlName="bankFeesIncurred" id="bankFeesIncurred" type="checkbox">
                            <span></span>
                          </label>
                          <label class="checkbox-label" for="bankFeesIncurred">Bank fees incurred</label>
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="checkbox-wrap">
                        <div class="form-group">
                          <label class="checkbox">
                            <input formControlName="fixedFeePerVisit" id="fixedFeePerVisit" type="checkbox">
                            <span></span>
                          </label>
                          <label class="checkbox-label" for="fixedFeePerVisit">Fixed fee per visit</label>
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="checkbox-wrap">
                        <div class="form-group">
                          <label class="checkbox">
                            <input formControlName="overspendApproved" id="overspendApproved" type="checkbox">
                            <span></span>
                          </label>
                          <label class="checkbox-label" for="overspendApproved">Sponsor Approved</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
                <div class="mt-4" *ngIf="selectedTab === 'approvalSuggestion'">
                  <div class="row">
                    <div class="col-6">
                      <div class="checkbox-wrap">
                        <div class="form-group">
                          <label class="checkbox">
                            <input formControlName="approvalNoReceipts" id="approvalNoReceipts" type="checkbox">
                            <span></span>
                          </label>
                          <label class="checkbox-label" for="approvalNoReceipts">Blanket approval for no
                            receipts</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6">
                      <p class="checkbox-label">Blanket approval for overspend on</p>
                    </div>
                    <div class="col-3">
                      <div class="checkbox-wrap">
                        <div class="form-group">
                          <label class="checkbox">
                            <input formControlName="overspendTravel" id="overspendTravel" type="checkbox">
                            <span></span>
                          </label>
                          <label class="checkbox-label" for="overspendTravel">Travel</label>
                        </div>
                      </div>
                    </div>
                    <div class="col-3">
                      <div class="checkbox-wrap">
                        <div class="form-group">
                          <label class="checkbox">
                            <input formControlName="overspendMileage" id="overspendMileage" type="checkbox">
                            <span></span>
                          </label>
                          <label class="checkbox-label" for="overspendMileage">Mileage</label>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="visit-details">
                        <span class="visit-details-label">Caregiver Traveling:</span>
                        <app-dropdown-input [className]="'details-dropdown'" #caregiverTravellingSelect
                          [parentForm]="form" name="caregiverTravelling" [options]="caregiverTravellingOptions"
                          placeholder="Select..."></app-dropdown-input>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="visit-details">
                        <span class="visit-details-label">Visit length in days:</span>
                        {{expenseCheck.visitLengthInDays}}
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="visit-details">
                        <span class="visit-details-label">Visit attendance:</span>
                        <app-dropdown-input [className]="'details-dropdown'" #attendanceSelect [parentForm]="form"
                          name="attendance" [options]="visitAttendanceOptions"
                          placeholder="Select..."></app-dropdown-input>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-12 mb-3">
              <app-approved-expenses-container [subTotals]="expenseCheck.subTotals"></app-approved-expenses-container>
            </div>
          </div>
        </div>
      </div>
      <div class="matching-rules" *ngIf="selectedTab === 'approvalSuggestion'">

        <label class="mt-1 mb-0" *ngIf="expenseCheck.rules?.length">Auto-suggest Decision path</label>
        <app-trial-policy-rules-list [expenseRules]="expenseCheck.rules" [showResult]="true">
        </app-trial-policy-rules-list>

        <label class="mt-3 mb-0"
          *ngIf="expenseCheck.rates?.length && expenseCheck.expenseCategoryType === ExpenseCategoryType.Distance">
          Matched mileage rate (for mileage)
        </label>
        <app-trial-policy-mileage-rates-list [mileageRateRules]="expenseCheck.rates"
          *ngIf="expenseCheck.expenseCategoryType === ExpenseCategoryType.Distance">
        </app-trial-policy-mileage-rates-list>
      </div>
    </form>

    <hr>

    <div class="buttons">
      <div class="row">
        <div class="col-12 col-lg-6">
          <button (click)="hide()" class="btn btn-secondary">Close</button>
          <button *ngIf="authService.hasPermission(Permissions.ExpenseWrite)" [disabled]="expenseCheck?.overBudgetRequestRaised" (click)="onReject()"
            class="btn btn-outline">Reject</button>
        </div>

        <div class="col-12 col-lg-6 right-col">
          <button class="btn btn-primary" [disabled]="expenseCheck?.overBudgetRequestRaised"
            *ngIf="authService.hasPermission(Permissions.ExpenseWrite)" (click)="onRaiseOverBudget()">
            <span *ngIf="overBudgetDetailsLoading">
              <span aria-hidden="true" class="spinner-border spinner-border-sm" role="status"></span>
            </span>
            <span *ngIf="!overBudgetDetailsLoading">Raise over budget</span>
          </button>
          <button *ngIf="authService.hasPermission(Permissions.ExpenseWrite)" (click)="onSave()"
            [disabled]="form.pristine" class="btn btn-outline">
            <span *ngIf="form.get('processing').value">
              <span aria-hidden="true" class="spinner-border spinner-border-sm" role="status"></span>
            </span>
            <span *ngIf="!form.get('processing').value">Save</span>
          </button>
          <button *ngIf="authService.hasPermission(Permissions.ExpenseWrite)" (click)="onApprove()"
            class="btn btn-primary">Approve</button>
        </div>
      </div>
    </div>
  </div>
</app-modal>

<app-edit-expense-modal #editExpenseModal (expenseUpdatedDetail)="onExpenseUpdated($event)"></app-edit-expense-modal>
<app-over-budget-categories-select-modal #overBudgetCategoriesSelectModal (categoriesSelected)="raiseOverBudgetRequestByCategories($event)"></app-over-budget-categories-select-modal>
<app-over-budget-request-modal #overBudgetRequestModal (overBudgetRequestRaised)="onOverBudgetRequestRaised()"></app-over-budget-request-modal>
