import { RoleNames } from './../../core/constants/role-names';
import { Component, HostListener, OnInit, Renderer2 } from '@angular/core';
import { NavigationItem } from "../header/navigation-item.model";
import { AuthService } from "../../core/services/auth.service";
import { AuthStatus } from "../../core/auth-status.enum";
import { environment } from 'environments/environment';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  userLoggedIn = false;
  rolesLoaded = false;
  allNavigationItems: NavigationItem[] = [];
  RoleNames = RoleNames;
  sidebarExpanded = false;

  // When the sidebar is expanded, clicking a link in the sidebar should collapse the sidebar
  @HostListener('click', ['$event.target']) onClick(target: HTMLElement) {
    // Check if the clicked target is an <a> element
    if (target.tagName === 'A') {
      if (target.parentElement.tagName === 'LI') {
        this.sidebarExpanded = false;
      }
    }
  }

  constructor(public authService: AuthService, private renderer: Renderer2) {
  }

  ngOnInit() {
    // Subscribe to rolesLoaded event to set navigation items when roles are loaded
    this.authService.rolesLoaded.subscribe(rolesLoaded => {
      this.rolesLoaded = rolesLoaded;
      if (rolesLoaded) {
        this.setNavigationItems();
      }
    });

    // Sometimes roles are cached, so set navigation items if roles are already stored
    if (this.authService.rolesAreStored()) {
      this.setNavigationItems();
    }

    this.authService.authStatus.subscribe(status => {
      this.userLoggedIn = status === AuthStatus.Authenticated;
    });
  }

  private setNavigationItems(): void {
    this.allNavigationItems = [
      new NavigationItem('Trials', '/trial', 'icon-asterix', [RoleNames.ProductAdministrator, RoleNames.ProjectCoordinatorExpenses, RoleNames.ProjectCoordinatorTravel, RoleNames.StartupCoordinator]),
      new NavigationItem('Patients', '/patient', 'icon-user', [RoleNames.ProductAdministrator, RoleNames.ProjectCoordinatorExpenses, RoleNames.ProjectCoordinatorTravel]),
      new NavigationItem('Visits', '/visit', 'far fa-calendar', [RoleNames.ProductAdministrator, RoleNames.ProjectCoordinatorExpenses, RoleNames.ProjectCoordinatorTravel]),
      new NavigationItem('Expenses', '/expense', 'icon-secure', [RoleNames.ProductAdministrator, RoleNames.ProjectCoordinatorExpenses, RoleNames.ProjectCoordinatorTravel]),
      new NavigationItem('Travel Requests', '/travel', 'icon-cabinet', [RoleNames.ProductAdministrator, RoleNames.ProjectCoordinatorExpenses, RoleNames.ProjectCoordinatorTravel]),
      new NavigationItem('Report', '/report', 'icon-icon-reimbursement', [RoleNames.ProjectManager, RoleNames.ProductAdministrator, RoleNames.ProjectCoordinatorTravel, RoleNames.StartupCoordinator, RoleNames.ProjectCoordinatorExpenses]),
      new NavigationItem('Sites', '/site', 'icon-shield', [RoleNames.ProductAdministrator, RoleNames.StartupCoordinator, RoleNames.ProjectCoordinatorExpenses, RoleNames.ProjectCoordinatorTravel]),
      new NavigationItem('Expense Categories', '/category', 'far fa-list-alt', [RoleNames.ProductAdministrator, RoleNames.ProjectCoordinatorExpenses, RoleNames.ProjectCoordinatorTravel, RoleNames.StartupCoordinator]),
      new NavigationItem('Caxton', '/caxton', null, [RoleNames.ProductAdministrator, RoleNames.StartupCoordinator, RoleNames.ProjectCoordinatorExpenses], '../../../assets/img/icon _ caxton.svg', 'Caxton Image'),
      new NavigationItem('Project Management', '/project-management', 'far fa-clipboard-check', [RoleNames.ProductAdministrator, RoleNames.ProjectManager, RoleNames.ProjectCoordinatorExpenses, RoleNames.ProjectCoordinatorTravel])
    ];
  }

  onLogout() {
    this.authService.logout(true);
  }

  onToggleMobileMenu() {
    this.sidebarExpanded = !this.sidebarExpanded;
  }

  itemVisible(roles: string[]): boolean {
    return this.authService.hasAnyRole(roles.join(','));
  }

  adminTabVisible(roles: string[]) {
    if (environment.environment === "DEVELOPMENT" || environment.environment === "STAGING") {
      roles.push(RoleNames.ProjectManager);
      roles.push(RoleNames.StartupCoordinator);
    }

    return this.itemVisible(roles);
  }

  onMoreClick(): void {
    return;
  }
}
